import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Altbausanierung = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Planst du eine Altbausanierung? Das solltest du vorher wissen" showCalc={false} />
            <Article>
                <p>
                    Wenn du eine Altbausanierung planst, musst du meistens mit sehr hohen Kosten rechnen. Wie hoch deine
                    Kosten sind, hängt natürlich davon ab, was in deiner Wohnung gemacht wird. Wir geben dir Tipps zur{" "}
                    <Link to="/artikel/wohnkredit/" target="_blank" rel="noreferrer noopener">
                        Finanzierung
                    </Link>{" "}
                    und zu Förderungen. Unsere Checkliste soll dir dabei helfen, die nötigen Arbeiten richtig zu planen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Das wichtigste: Eine gute Planung</h2>
                <p>
                    Du solltest dir vor der Altbausanierung sehr gut überlegen, was du an deiner Wohnung erneuern
                    möchtest. Sanierungen können unterschiedlichste Arbeiten beinhalten: von neuen Fenstern über neue
                    Fußböden, Wärmedämmungen bis zu neuen Elektroinstallationen und Sanitäranlagen. Je mehr du auf
                    einmal sanieren kannst, desto länger wird deine Wohnung sich in einem Zustand befinden, mit dem du
                    zufrieden bist. Je mehr Arbeit du jetzt investierst, desto länger hast du deine Ruhe. Deshalb kann
                    es sich oft lohnen einiges zu investieren und ein Sanierungsdarlehen aufzunehmen, Plane also vorher
                    gut, was du tun kannst, um deinen Altbau in den idealen Zustand zu bringen, mit dem du lange
                    zufrieden sein wirst.
                </p>
                <p>
                    Check auch den miracl{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kredit-Rechner
                    </Link>{" "}
                    an und hol dir eine erste Einschätzung zu Zinsen, Raten und mehr.
                </p>
                <hr />

                <h2>Checkliste zur Altbausanierung</h2>
                <p>
                    Du hast eine sanierungsbedürftige Altbauwohnung{" "}
                    <Link to="/artikel/kosten-wohnung/" target="_blank" rel="noreferrer noopener">
                        gekauft
                    </Link>{" "}
                    und möchtest sie nun auf Vordermann bringen? Am besten erstellst du eine Liste mit allen Bereichen,
                    die saniert werden sollen. Dann reihst du sie nach Priorität und überlegst dir, wie viel Zeit und
                    Geld welche Arbeit benötigen wird. Vergiss nicht, dass viele Sanierungsarbeiten im Altbau staatlich
                    gefördert werden.
                </p>
                <p>Überprüfe den Zustand von:</p>
                <ul>
                    <li>Dach – Ist das Dach in Ordnung oder hängen Latten im Dachstuhl durch?</li>
                    <li>Fassade – Gedämmt oder nicht?</li>
                    <li>Innenwände</li>
                    <li>Gibt es möglicherweise Schimmel, der entfernt werden muss? Sind die Wände feucht?</li>
                    <li>Decke – In welchem Zustand befindet sich die Decke und aus welchem Material sind sie?</li>
                    <li>Fußboden</li>
                    <li>Fenstern und Türen</li>
                    <li>Sanitäranlagen</li>
                    <li>
                        Elektroinstallationen – soll etwas neu verlegt werden? Sind Fehlerstrom- (FI) und
                        Leitungsschutzschalter vorhanden? Sind die Leitungen bereits in Schutzschläuchen verlegt oder
                        muss das noch erledigt werden?
                    </li>
                    <li>In welchem Zustand befindet sich der Keller und hat die Decke eine Wärmedämmung?</li>
                    <li>Wie wird die Wohnung geheizt? Wie alt ist das Heizsystem und kann es saniert werden?</li>
                    <li>Ist der Rauchfang in einem guten Zustand?</li>
                    <li>Ist es im Sommer heiß, brauchst du eine Klimaanlage?</li>
                    <li>Zieht es und wie kann das behoben werden?</li>
                </ul>
                <p>
                    <strong>Tipp:</strong> Wenn du dir unsicher bist, solltest du stets Handwerker oder Architekten, die
                    mit Altbausanierungen bereits Erfahrung haben, zu Rate ziehen, um den Zustand richtig zu beurteilen.
                </p>
                <hr />

                <h2>Mit diesen Kosten kannst du rechnen</h2>
                <p>
                    Die{" "}
                    <Link to="/artikel/kosten-wohnung/" target="_blank" rel="noreferrer noopener">
                        Kosten
                    </Link>{" "}
                    einer Altbausanierung können ganz schön hoch sein. Wie hoch sie letztlich ausfallen, hängt davon ab,
                    in welchem Zustand sich der Altbau befindet. Fußböden verlegen zu lassen ist teuer, wenn ein neuer
                    Parkettboden verlegt werden soll. Manchmal reicht es völlig den bestehenden Holzboden abschleifen
                    und lackieren zu lassen, damit er aussieht wie neu. Wenn es ein neuer Boden sein soll, gibt es viele
                    günstige Alternativen zu Parkett und Fliesen. Bei einem Heizungstausch musst du meist mit Kosten
                    über 15.000 Euro rechnen. Bei Trockenlegungsarbeiten mit mehreren hundert Euro, je nachdem wie man
                    diese durchführt und um welches Material es sich handelt. Badsanierungen sind ebenfalls ziemlich
                    teuer, wobei hier die Installationskosten meistens den Ausschlag geben. Hier kann es sich lohnen,
                    wenn du Handwerker und Installateure des Vertrauens hast, oder sogar welche in deinem Bekanntenkreis
                    vertreten sind. Kosten von Wärmedämmungen werden nach Quadratmeter verrechnet und ein Preisvergleich
                    kann sich hier sehr lohnen.
                </p>
                <p>
                    Zu deinen Kosten können auf diese Art natürlich nur Richtlinien gegeben werden, weil die exakten
                    Preise von der Altbauwohnung und deinen Veränderungswünschen abhängen. Grundsätzlich kann man bei
                    einer Altbausanierung in Österreich im Schnitt mit Kosten von ca. 1.000 Euro pro Quadratmeter
                    rechnen. Wenn die Wohnung also 100 Quadratmeter hat, zahlst du bereits 100.000 Euro für die
                    Altbausanierung. Wenn es Auflagen zum Denkmalschutz gibt, kann es noch um einiges teurer werden.
                </p>
                <hr />

                <h2>Finanzierung der Altbausanierung</h2>
                <p>
                    Bevor du mit der Altbausanierung beginnst, sollte die Finanzierung bereits sicher sein. Das heißt,
                    dass du dich auch rechtzeitig über{" "}
                    <Link to="/artikel/bauen-oder-kaufen/" target="_blank" rel="noreferrer noopener">
                        Fördermöglichkeiten
                    </Link>{" "}
                    informieren solltest. Darauf gehen wir weiter unten nochmal ein. Um die Gesamtkosten für deine
                    Altbausanierung zu berechnen, solltest du dir mindestens drei Angebote von unterschiedlichen Firmen
                    einholen und miteinander vergleichen. Formuliere deine Anfragen identisch, das erleichtert dir den
                    direkten Vergleich. Reichen deine Eigenmittel für die Altbausanierung? Wenn nicht, solltest du dich
                    über die Möglichkeit einer Finanzierung informieren. Banken bieten beispielsweise
                    Modernisierungskredite an. Sobald du die genauen Kosten ermittelt hast, kannst du dich an einen
                    Finazierungsexperten oder an die Bank wenden, um Finanzierungsmöglichkeiten zu besprechen. Dabei
                    sollten wie bei jedem Kredit mehrere Angebote miteinander verglichen werden, weil es unzählige
                    unterschiedliche Angebote gibt. Die einzelnen Bundesländer in Österreich bieten unterschiedliche
                    Sanierungsdarlehen an. Altbausanierungen werden in jedem Bundesland gefördert.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Hol dir hier eine Beratung von unseren Finanzierungsexperten!
                    </a>
                </p>
                <hr />

                <h2>Welche Förderungen gibt es für die Altbausanierung?</h2>
                <p>
                    Bevor du über die{" "}
                    <Link to="/artikel/wohnkredit/" target="_blank" rel="noreferrer noopener">
                        Finanzierung
                    </Link>{" "}
                    mit deiner Bank nachdenkst, solltest du genau über die Fördermöglichkeiten in deinem Bundesland
                    bescheid wissen. Die Förderungen gehen weit über thermische Sanierungen hinaus und umfassen bei der
                    Altbausanierung glücklicherweise alle Bereiche. Aber die Höhe ist begrenzt und unterscheidet sich je
                    nach Bundesland. Erneuerbare Energien werden überall gefördert, wie zum Beispiel der Einbau von
                    Solarzellen.
                </p>
                <p>
                    Thermische Sanierungen sind seit diesem Jahr in allen Bundesländern einheitlich geregelt und werden
                    mit einer Höhe von bis zu 30% der Kosten gefördert. Es gibt je nach Sanierungsart festgelegte
                    Obergrenzen, über die du dich online informieren kannst. Wenn du nachhaltige Dämmstoffe verwendest,
                    erhöht sich deine Förderung um maximal 50%.
                </p>
                <p>
                    Über die unterschiedlichen Förderungen je nach Bundesland kannst du dich online auf den
                    bundesland.gv.at-Seiten informieren. Jedes Bundesland hat auf der eigenen Website Informationen zu
                    Sanierungsdarlehen.
                </p>
                <hr />

                <h2>Welche Voraussetzungen gibt es?</h2>
                <p>
                    Um für eine geförderte Altbausanierung in Frage zu kommen, muss das Gebäude älter als zwanzig Jahre
                    sein. Die geförderte Wohnung oder das Haus müssen außerdem als Hauptwohnsitz gemeldet sein. Als
                    Antragsteller*in musst du Staatsbürger*in eines EWR-Staates sein und nur innerhalb der jeweils
                    festgelegten Einkommensgrenzen verdienen.{" "}
                </p>
                <p>
                    Also noch einmal alles zusammengefasst: Planung ist das A und O. Was wird wie saniert? Die Kosten
                    der Altbausanierung müssen genau ermittelt und die verschiedenen Kostenvoranschläge dringend
                    miteinander verglichen werden, um mehrere tausend Euro einzusparen. Alle Bereiche der
                    Altbausanierung werden gefördert, vor allem wenn es um erneuerbare Energien oder nachhaltige
                    Sanierungen handelt. Hier unterscheiden sich Sanierungsdarlehen je nach Bundesland. Infos zu deinem
                    Bundesland findest du auf der jeweiligen Website. Über all diese Dinge musst du dich gut
                    informieren, bevor du dich an die Sache machst. Scheue nicht davor zurück, dir bei diesem großen
                    Vorhaben Hilfe zu holen.
                </p>
            </Article>

            <BreadcrumbList page={"altbausanierung"}></BreadcrumbList>
            <ArticleStructuredData
                page={"altbausanierung"}
                heading={"Planst du eine Altbausanierung? Das solltest du vorher wissen"}
            />
        </Layout>
    );
};

export default Altbausanierung;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.altbausanierung", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
